<template>
  <section class="main-wrap">
    <header class="inner-page-header">
      <router-link
        v-if="selectedClient && selectedCampaign"
        class="client-profil-wrap"
        :to="{
          name: 'Campaign',
          params: {
            clientId: selectedClient.id,
            campaignId: selectedCampaign.id,
          },
        }"
      >
        <div class="overlay-back">
          <svg width="7" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5.768 12c.317 0 .621-.126.864-.366.475-.492.475-1.301 0-1.793L2.945 6l3.7-3.839c.474-.492.474-1.3 0-1.793a1.195 1.195 0 0 0-1.729 0L.353 5.105C.122 5.345 0 5.66 0 6c0 .341.134.657.353.897l4.551 4.736c.244.24.56.366.864.366z"
              fill="#fff"
            />
          </svg>
        </div>
        <client-logo v-if="selectedClient" :client="selectedClient" class-name="is-medium"></client-logo>
      </router-link>

      <div v-if="selectedAdz" class="header-title">
        <h1 class="big-title" @click.prevent="enterLiveEditMode" @keyup="saveLiveEdit" @blur="endLiveEdit">
          {{ selectedAdz.name }}
        </h1>
        <h2 class="small-title">Create your Ad</h2>
      </div>
    </header>

    <div class="content-wrap">
      <div class="left-wrap">
        <form v-if="adz" action="#" class="create-fb-ads-form" @submit="saveAdzContent">
          <input id="file" type="file" name="file" class="add-file" accept=".png, .jpg, .mp4" placeholder="Image ou vidéo" @change="uploadFile($event)" />
          <p class="sub-label" v-if="tempOriginalFileImg">
            <a href="#" class="link-p" @click.prevent="resetCrop(tempOriginalFileImg)"> Crop again </a>
          </p>
          <p class="sub-label">
            For questions and more information, see the
            <a href="https://www.facebook.com/business/ads-guide/" target="_blank" class="link-p"> Facebook Ad Guidelines. </a>
          </p>

          <div class="field-wrap">
            <div class="input-label-wrap" :class="{ filled: adz.content.text }">
              <textarea v-model="adz.content.text" class="label-focus textarea-focus" @keyup="countdownText"></textarea>
              <p class="label">Primary Text</p>
            </div>
            <p class="counter" :class="{ 'text-danger': hasErrorText }">
              {{ remainingCountText }}
            </p>
          </div>
          <p class="sub-label">This is the primary text for your ad that will appear in all placements.</p>

          <div class="field-wrap">
            <div class="input-label-wrap" :class="{ filled: adz.content.metaTitle }">
              <input v-model="adz.content.metaTitle" type="text" class="label-focus" @keyup="countdownTitre" />
              <p class="label">Headline (optional)</p>
            </div>
            <p class="counter" :class="{ 'text-danger': hasErrorUrlText }">
              {{ remainingCountTitre }}
            </p>
          </div>
          <p class="sub-label">Write a short headline</p>

          <div class="input-label-wrap" :class="{ filled: adz.content.metaDesc }">
            <textarea v-model="adz.content.metaDesc" class="label-focus textarea-focus"></textarea>
            <p class="label">Description (optional)</p>
          </div>
          <p class="sub-label">Include additional details</p>

          <div class="field-wrap">
            <div class="input-label-wrap flex" :class="{ filled: adz.content.cta }">
              <div class="input-text-smaller">
                <select v-model="adz.content.cta" class="label-focus" name="cta" @change="setCtaLabel($event)">
                  <option value=""></option>
                  <optgroup v-for="group in ctaOptions" :key="group.label" :label="group.label">
                    <option v-for="cta in group.ctas" :key="cta" :value="cta">{{ translateCtaLabel(cta) }}</option>
                  </optgroup>
                </select>
                <p class="label">Call to Action</p>
              </div>
              <div class="checkbox-wrap" :class="{ active: ctaLang === 'fr' }" @click.prevent="setCtaLabelLang()">
                <p>French</p>
                <div class="checkbox"><img src="@/assets/img/check-icon-grey.svg" alt="check" /></div>
              </div>
            </div>
          </div>
          <p class="sub-label">Show a button or link on your ad that represents the action you want people to take. <br/> *Some actions are unique to the Campaign objective.</p>

          <div class="input-label-wrap" :class="{ filled: adz.content.url }">
            <input v-model="adz.content.url" type="text" class="label-focus" />
            <p class="label">Website URL</p>
          </div>
          <p class="sub-label">Enter the URL for the web page you want people to visit.</p>

          <div v-if="autoDisplayLink && adz.content.url.length >= 13" class="field-wrap">
            <div class="input-label-wrap flex" :class="{ filled: autoDisplayLink }">
              <div class="input-text-smaller">
                <input v-model="autoDisplayLink" type="text" class="label-focus smaller" disabled />
                <p class="label">Display Link (optional)</p>
              </div>
              <div class="checkbox-wrap" :class="{ active: useAutoDisplayLink }" @click.prevent="useAutoDisplayLink = !useAutoDisplayLink">
                <p>Show</p>
                <div class="checkbox"><img src="@/assets/img/check-icon-grey.svg" alt="check" /></div>
              </div>
            </div>
          </div>
          <p class="sub-label">&nbsp;</p>

          <div class="multiple-action-button">
            <div class="main-button">
              <button type="submit" to="/Campaign" class="default-action save" @mouseover="setAction('exit')">
                <p>Save</p>
              </button>
              <div class="more" @click="showMoreOptions">
                <img src="../../assets/img/arrow-down.svg" alt="Arrow down" />
              </div>
            </div>
            <div class="options" :class="{ show: moreOptions }">
              <button type="submit" to="/Facebook" @mouseover="setAction('show')"><p>Save and show</p></button>
              <button type="submit" to="/Facebook" @mouseover="setAction('duplicate')">
                <p>Save and duplicate</p>
              </button>
            </div>
          </div>
        </form>
      </div>
      <!--LEFT WRAP-->

      <div class="right-wrap">
        <!-- SIMPLE PRODUIT 1:1 -->
        <SimpleProduit
          v-if="adz"
          :adz="adz"
          :client="selectedClient"
          :is-saving="isSaving"
          :upload-progress="imageProgress"
          :show-approve="false"
          :account-id="account[0].id"
          :client-id="$route.params.clientId"
          :campaign-id="$route.params.campaignId"
          :adz-id="this.$route.params.adzId"
          :platform="'facebook'"
        />
      </div>
      <!--RIGHT WRAP-->
    </div>

    <!-- Cropper Popup -->
    <div :class="['croppper-popup-wrapper', { 'is-active': imgSrc && !shouldClosePopup }]">
      <div class="cropper-popup">
        <vue-cropper
          v-if="imgSrc"
          ref="cropper"
          :aspect-ratio="1 / 1"
          :drag-mode="'move'"
          :movable="true"
          :rotatable="false"
          :crop-box-movable="true"
          :crop-box-resizable="false"
          :responsive="true"
          :src="imgSrc"
          alt="Source Image"
          preview=".preview"
        >
        </vue-cropper>

        <div v-if="imgSrc" class="actions">
          <div class="zoom-wrap">
            <a href="#" @click.prevent="zoomIn">+<small>1</small></a>
            <a href="#" @click.prevent="zoomInTwice">+<small>2</small></a>

            <a href="#" @click.prevent="zoomOut">-<small>1</small></a>
            <a href="#" @click.prevent="zoomOutTwice">-<small>2</small></a>
          </div>
          <div class="aspectratio-wrap">
            <a href="#" :class="{ active: ratioToggle }" @click="ratioToggleTrue" @click.prevent="changeAspectRatio(1 / 1, '1:1')">1:1</a>
            <a href="#" :class="{ active: !ratioToggle }" @click="ratioToggleFalse" @click.prevent="changeAspectRatio(1.91 / 1, '1.91:1')">1.91:1</a>
          </div>
          <a href="#" role="button" class="bt" @click.prevent="cropImage">Crop</a>
        </div>

        <a href="#" class="close-action">
          <img src="@/assets/img/close.svg" alt="X" @click.prevent="closePopup" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
// Cropper
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

import { mapState, mapActions } from 'vuex'
import { isNil } from 'lodash'
import { storageRef, firebase } from '@/firebase/init'
import SimpleProduit from '@/components/facebook/SimpleProduit'
import { resetCommentsOnAdz, applyDuplicateIdRefs, slugify, doAlert } from '@/misc/helpers'

import AccountClientCampaignAdzDB from '@/firebase/account-client-campaign-adz-db'
import FbAdzCallToActionsDB from '@/firebase/fbAdzCallToActions-db'
import CleanupJobsDB from '@/firebase/cleanup/cleanup-jobs-db'
// import PendingVideosConvertionsDB from '@/firebase/pending-videos-convertions-db'

import ClientLogo from '@/components/clients/ClientLogo'
import { translatedCta } from '@/misc/fbAdzCtasTranslation'

export default {
  head: function () {
    return {
      title: {
        inner: 'Create your Facebook Ad',
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc',
        },
      ],
    }
  },

  components: {
    SimpleProduit,
    ClientLogo,
    VueCropper,
  },

  data: function () {
    return {
      // Julien :: Refactor in progress
      maxCountText: 125,
      remainingCountText: 125,
      maxCountUrl: 30,
      remainingCountUrl: 30,
      maxCountTitre: 40,
      remainingCountTitre: 40,

      hasErrorText: false,
      hasErrorUrl: false,
      hasErrorUrlText: false,
      ratioToggle: true,

      // Vinc :: Refacto
      mode: 'create',
      action: 'exit',
      adz: null,
      isSaving: false,
      imageUrl: '',
      imageProgress: 0,
      imageRatio: '1:1', // Default Cropper Ratio

      moreOptions: false,
      liveEditBuffer: null,
      liveEditTarget: null,

      ctaOptions: null,
      ctaLang: 'en',

      imgSrc: '',
      cropImg: '',
      data: null,
      shouldClosePopup: false,
      hasCropped: false,
      useAutoDisplayLink: true,
      error: '',

      tempOriginalFileImg: null,
    }
  },

  computed: {
    clientId() {
      return this.$route.params.clientId
    },
    campaignId() {
      return this.$route.params.campaignId
    },
    adzId() {
      return this.$route.params.adzId
    },
    autoDisplayLink() {
      if (!this.adz) {
        return ''
      }
      if (!this.adz.content.url) {
        return ''
      }

      let displayUrl = new URL(this.adz.content.url)
      if (displayUrl && displayUrl.host) {
        displayUrl = displayUrl.host
      }
      return displayUrl.replace('www.', '')
    },
    ...mapState('app', ['appTitle']),
    ...mapState('users', ['account', 'selectedClient', 'selectedCampaign', 'selectedAdz']),
  },

  watch: {
    account: {
      async handler(account) {
        if (!isNil(account)) {
          // console.log('account', 'changed')

          this.selectClient(this.$route.params.clientId)
          this.selectCampaign({
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          })
          this.selectAdz({
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
            adzId: this.$route.params.adzId,
          })

          if (this.clientId && this.campaignId && !this.adzId) {
            this.mode = 'create'
          } else if (this.clientId && this.campaignId && this.adzId) {
            this.mode = 'update'

            const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.account[0].id, this.clientId, this.campaignId)
            this.adz = await accountClientCampaignAdzDbRef.read(this.adzId)

            if (this.adz.hasAutoDisplayLink && this.adz.hasAutoDisplayLink === 'yes') {
              this.useAutoDisplayLink = this.adz.useAutoDisplayLink
            }

            if (this.adz.type === 'multiple' && this.$route.name === 'CreateFacebookAds') {
              this.$router.push({
                name: 'CreateFacebookCarrousel',
                params: {
                  clientId: this.selectedClient.id,
                  campaignId: this.selectedCampaign.id,
                  adzId: this.adz.id,
                },
              })
            }

            if (this.adz.type === 'stories' && this.$route.name === 'CreateFacebookAds') {
              this.$router.push({
                name: 'CreateFacebookStories',
                params: {
                  clientId: this.selectedClient.id,
                  campaignId: this.selectedCampaign.id,
                  adzId: this.adz.id,
                },
              })
            }

            this.countdownText()
            this.countdownUrl()
            this.countdownTitre()

            this.getCtaOptions()
          }
        }
      },
      immediate: true,
    },
    autoDisplayLink: {
      async handler(autoDisplayLink) {
        if (!this.adz || !this.adz.content) {
          return
        }

        if (autoDisplayLink && this.useAutoDisplayLink) {
          this.adz.content.urlText = this.autoDisplayLink
        } else {
          this.adz.content.urlText = ''
        }

        this.adz.useAutoDisplayLink = this.useAutoDisplayLink
        this.adz.hasAutoDisplayLink = 'yes'
      },
      immediate: true,
    },
    useAutoDisplayLink: {
      async handler(useAutoDisplayLink) {
        if (!this.adz || !this.adz.content) {
          return
        }

        if (this.autoDisplayLink && useAutoDisplayLink) {
          this.adz.content.urlText = this.autoDisplayLink
        } else {
          this.adz.content.urlText = ''
        }

        this.adz.useAutoDisplayLink = useAutoDisplayLink
        this.adz.hasAutoDisplayLink = 'yes'
      },
      immediate: true,
    },
  },

  methods: {
    translateCtaLabel(label) {
      return translatedCta(label, this.ctaLang)
    },
    setAction(action) {
      this.action = action
    },
    async saveLiveEdit(e) {
      clearTimeout(this.liveEditBuffer)
      this.liveEditBuffer = null

      this.liveEditBuffer = setTimeout(async () => {
        const target = e.target

        this.liveEditTarget = target

        if (target.getAttribute('contenteditable')) {
          const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(this.account[0].id, this.clientId, this.campaignId)

          const updateAdz = { ...this.adz, name: target.textContent.trim() }

          await accountClientCampaignAdzDbRef.update(updateAdz)
          this.adz = updateAdz
        }
      }, 635)
    },
    endLiveEdit() {
      this.liveEditTarget.removeAttribute('contenteditable')

      // Refresh State
      this.selectAdz({
        clientId: this.$route.params.clientId,
        campaignId: this.$route.params.campaignId,
        adzId: this.$route.params.adzId,
      })
    },
    async uploadFile(event) {
      const uploadedFile = event.target.files[0]
      const acceptedFormats = ['image/jpeg', 'image/png', 'video/mp4']

      let isVideo = false
      let ogFileName = this.adz.name ? this.adz.name.trim() : uploadedFile.name

      // Slugify the ogFileName
      ogFileName = await slugify(ogFileName)

      // Validate Format
      if (!acceptedFormats.includes(uploadedFile.type)) {
        doAlert({
          title: 'This file type is not supported',
          type: 'error',
        })
        return
      }

      isVideo = uploadedFile.type.includes('video')

      if (isVideo) {
        // Validate Size
        if (uploadedFile.size / 1000 > 900000) {
          doAlert({
            title: 'File size must be under 1GB',
            type: 'error',
          })
          return
        }

        window.URL = window.URL || window.webkitURL

        const uploadedVideo = document.createElement('video')
        uploadedVideo.preload = 'metadata'
        const vueInstance = this

        uploadedVideo.onloadedmetadata = function () {
          window.URL.revokeObjectURL(uploadedVideo.src)
          vueInstance.adz.format = ''
          vueInstance.uploadVideo(uploadedFile, ogFileName)
        }

        uploadedVideo.src = URL.createObjectURL(uploadedFile)
      } else {
        // Validate Size
        if (uploadedFile.size / 1000 > 10000) {
          doAlert({
            title: 'File size must be under 10MB',
            type: 'error',
          })
          return
        }

        const vueInstance = this
        const _URL = window.URL || window.webkitURL
        const img = new Image()
        img.onload = function () {
          if (this.width < 600) {
            doAlert({
              title: 'Image width/height must be at least 600px',
              type: 'error',
            })
            return
          }
          // console.log('Image Ratio detection')
          // console.log(vueInstance.aspectRation(this.width, this.height))
          // const checkRatio = vueInstance.aspectRation(this.width, this.height)
          // if (['1:1', '4:5', '1.91:1'].includes(checkRatio)) {
          // if (true) {
          // vueInstance.adz.format = checkRatio
          vueInstance.adz.format = ''
          vueInstance.uploadImage(uploadedFile, ogFileName, true)
          // } else {
          //   vueInstance.doAlert({
          //     title: 'Invalid ratio for this image. Please update a valid ratio.',
          //     type: 'error'
          //   })
          //   return
          // }
        }
        img.src = _URL.createObjectURL(event.target.files[0])
      }

      return
    },
    async uploadImage(uploadedFile, uploadedFileName, triggerCropper = true) {
      // Create a unique name/sku for the image
      const fileName = `${this.adz.id}-${new Date().valueOf()}.jpg`
      // console.log(`🖼 name === ${fileName}`)

      // https://firebasestorage.googleapis.com/v0/b/showadz-prod-6a02e.appspot.com/o/KDepwKsFbiSYt0uCYxWP%2Fadz%2F0GttHsm3yCsGeU2qQxkg%2Fthumbs2%2F0GttHsm3yCsGeU2qQxkg-1639099978499_300x300.jpg?alt=media&token=4d76a53a-09c3-4c3f-9608-0a4018e8c7f1&t=1639099994678
      const uploadTask = storageRef.child(`${this.account[0].id}/adz/${this.adz.id}/${fileName}`).put(uploadedFile)

      this.isSaving = true

      // We stop the uploading,
      // We let the user choose the image focus zone
      if (triggerCropper) {
        // Show the preview to the user
        const reader = new FileReader()
        reader.readAsDataURL(uploadedFile)
        reader.onload = () => {
          this.tempOriginalFileImg = reader.result
          this.imgSrc = this.tempOriginalFileImg
          this.shouldClosePopup = false
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(this.tempOriginalFileImg)
          this.isSaving = false
        }
        return
      }

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.imageProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + this.imageProgress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log(error)
          this.isSaving = false
          this.error = error
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            // console.log('File available at', downloadURL)

            // Video
            this.adz['content']['videoMp4'] = ''
            this.adz['content']['videoRef'] = ''
            this.adz['content']['videoWebm'] = ''
            this.adz['content']['webmVersion'] = ''

            // Image
            this.adz['content']['imageRef'] = fileName
            this.adz['content']['imageRefFromId'] = ''
            this.adz['content']['imageRefVersion'] = '2.0.0'
            this.adz['content']['imageFormats'] = '300x300,600x600,1200x1200,1200x628'
            this.adz['content']['imageRatio'] = this.imageRatio
            this.adz['content']['hasThumbs'] = true
            this.adz['content']['ogImageName'] = uploadedFileName

            this.adz['content']['hasCropped'] = true
            this.hasCropped = true
            document.querySelector('.add-file').value = ''

            const cleanupJobsDB = new CleanupJobsDB()
            await cleanupJobsDB.create({
              type: 'adz',
              accountId: this.account[0].id,
              clientId: this.clientId,
              campaignId: this.campaignId,
              imageRef: fileName,
              filePath: `${this.account[0].id}/adz/${this.adz.id}/${fileName}`,
            })

            //
            setTimeout(() => {
              this.adz['t'] = new Date().getTime()
              this.adz['content']['tt'] = new Date().getTime()
              this.adz['content']['image'] = ''
              this.adz['content']['image'] = `${downloadURL}&t=${new Date().getTime()}`
              const ctaValueTriggerChange = document.querySelector('select[name="cta"]').value
              document.querySelector('select[name="cta"]').value = ''
              // console.log(this.adz.t)

              setTimeout(() => {
                this.adz['t'] = new Date().getTime()
                this.adz['content']['tt'] = new Date().getTime()
                this.adz['content']['image'] = ''
                this.adz['content']['image'] = `${downloadURL}&t=${new Date().getTime()}`
                document.querySelector('select[name="cta"]').value = ctaValueTriggerChange
                // console.log(this.adz.t)

                this.isSaving = false
                setTimeout(() => {
                  this.adz['t'] = new Date().getTime()
                  this.adz['content']['tt'] = new Date().getTime()
                  this.adz['content']['image'] = ''
                  this.adz['content']['image'] = `${downloadURL}&t=${new Date().getTime()}`
                  // console.log(this.adz.t)
                  setTimeout(() => {
                    this.adz['t'] = new Date().getTime()
                    this.adz['content']['tt'] = new Date().getTime()
                    this.adz['content']['image'] = ''
                    this.adz['content']['image'] = `${downloadURL}&t=${new Date().getTime()}`
                    // console.log(this.adz.t)
                  }, 2000)
                }, 2000)
              }, 2000)
            }, 2000)
          })
        }
      )
    },
    async uploadVideo(uploadedFile, uploadedFileName) {
      // Create a unique name/sku for the image
      const fileName = `${this.adz.id}}-${new Date().getTime()}.mp4`
      // console.log(`📹 name === ${fileName}`)

      const uploadTask = storageRef.child(`${this.account[0].id}/adz/${this.adz.id}/${fileName}`).put(uploadedFile)

      this.isSaving = true
      this.adz['content']['videoMp4'] = ''
      this.adz['content']['videoRef'] = ''

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          this.imageProgress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          // console.log('Upload is ' + this.imageProgress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              // console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              // console.log('Upload is running')
              break
          }
        },
        (error) => {
          // Handle unsuccessful uploads
          // console.log(error)
          this.isSaving = false
          this.error = error
        },
        () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(async (downloadURL) => {
            // console.log('File available at', downloadURL)
            this.adz['content']['image'] = ''
            this.adz['content']['imageRef'] = ''
            this.adz['content']['hasThumbs'] = false
            this.adz['content']['videoMp4'] = downloadURL
            this.adz['content']['videoRef'] = fileName
            this.adz['content']['videoRefFromId'] = ''
            this.adz['content']['videoWebm'] = ''
            this.adz['content']['webmVersion'] = ''
            this.adz['content']['ogVideoName'] = uploadedFileName
            this.adz = { ...this.adz, reRender: true }

            const cleanupJobsDB = new CleanupJobsDB()
            await cleanupJobsDB.create({
              type: 'adz',
              accountId: this.account[0].id,
              clientId: this.clientId,
              campaignId: this.campaignId,
              imageRef: fileName,
            })

            // Use cloud functions to fetch Webm version and save it to Adz
            // Video Preview will be generated later...
            // this.adz['content']['videoWebm'] = url
            // this.adz['content']['webmVersion'] = '1.0.0'

            // We create a db ref in pendingVideosConvertions DB
            // const pendingVideosConvertionsDbRef = new PendingVideosConvertionsDB()

            // await pendingVideosConvertionsDbRef.create({
            //   accountId: this.account[0].id,
            //   clientId: this.clientId,
            //   campaignId: this.campaignId,
            //   fileName,
            //   concact: `${this.account[0].id}-${this.clientId}-${this.campaignId}-${fileName}`,
            //   isConverted: false,
            //   type: 'single'
            // })

            this.isSaving = false
            delete this.adz.reRender
            this.adz = { ...this.adz, reRendered: true }
          })
        }
      )
    },
    ratioToggleTrue() {
      this.ratioToggle = true
    },
    ratioToggleFalse() {
      this.ratioToggle = false
    },
    closePopup() {
      if (this.shouldClosePopup) {
        this.shouldClosePopup = false
      } else {
        this.shouldClosePopup = true
        document.querySelector('.add-file').value = ''
        this.isSaving = false
      }
    },
    zoomIn() {
      this.$refs.cropper.relativeZoom(0.005)
    },
    zoomOut() {
      this.$refs.cropper.relativeZoom(-0.005)
    },
    zoomInTwice() {
      this.$refs.cropper.relativeZoom(0.25)
    },
    zoomOutTwice() {
      this.$refs.cropper.relativeZoom(-0.25)
    },
    changeAspectRatio(value, label) {
      this.$refs.cropper.setAspectRatio(value)
      this.imageRatio = label
    },
    async cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: 2000,
          maxHeight: 2000,
        })
        .toBlob(async (blob) => {
          // console.log({blob})
          let fileName = this.adz.name ? this.adz.name.trim() : 'blob'
          // Slugify the fileName
          fileName = await slugify(fileName)
          this.uploadImage(blob, fileName, false)
        })
      this.shouldClosePopup = true
    },
    async saveAdzContent(e) {
      e.preventDefault()

      // Abort if is currently loading/saving
      if (this.isSaving) {
        return
      }

      if (!this.validateUserInput()) {
        return
      }

      if (this.mode === 'create' && !this.hasCropped) {
        doAlert({
          title: 'Please use the cropper for a compatible Facebook Ratio',
          type: 'error',
        })
        return
      }

      this.isSaving = true

      // Is Adz considered as 'Ready' to be selected and Previewed?
      if ((this.adz.content.image || this.adz.content.videoMp4) && this.adz.content.text && this.adz.content.url && this.adz.content.cta) {
        this.adz.isReady = true
      } else {
        this.adz.isReady = false
      }

      this.adz.isArchived = false
      this.adz.useAutoDisplayLink = this.useAutoDisplayLink
      this.adz.hasAutoDisplayLink = 'yes' // New behaviour 'check'

      let dbAdz = this.adz

      try {
        const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(
          this.account[0].id,
          this.$route.params.clientId,
          this.$route.params.campaignId
        )
        dbAdz = await accountClientCampaignAdzDbRef.update(this.adz)
        this.isSaving = false
      } catch (error) {
        // console.log({ error })
        doAlert({ title: error, type: 'error' })
        this.isSaving = false
        return
      }

      let dbAdzId = dbAdz

      if (this.mode === 'create') {
        dbAdzId = dbAdz.id
      }

      if (this.action === 'duplicate') {
        let newAdz = {
          ...this.adz,
          name: `${this.adz.name} (copy)`,
        }
        newAdz.name = newAdz.name.trim()
        newAdz = applyDuplicateIdRefs(newAdz)
        delete newAdz.id

        try {
          const accountClientCampaignAdzDbRef = new AccountClientCampaignAdzDB(
            this.account[0].id,
            this.$route.params.clientId,
            this.$route.params.campaignId
          )
          dbAdz = await accountClientCampaignAdzDbRef.create(resetCommentsOnAdz(newAdz), null)
          // console.log('Created', dbAdz)
          this.isSaving = false
        } catch (error) {
          // console.log({ error })
          doAlert({ title: error, type: 'error' })
          this.isSaving = false
          return
        }
      }

      // Show Success Prompt
      doAlert({
        title: `Adz saved successfully`,
        type: 'success',
      })
      this.adz = null

      if (this.action === 'show') {
        this.$router.push({
          name: 'Facebook',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
            adzId: dbAdzId,
          },
        })
      } else if (this.action === 'duplicate') {
        dbAdzId = dbAdz.id
        this.$router.push({
          name: 'Campaign',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          },
          query: { select: dbAdzId },
        })
      } else {
        this.$router.push({
          name: 'Campaign',
          params: {
            clientId: this.$route.params.clientId,
            campaignId: this.$route.params.campaignId,
          },
        })
      }
    },
    gcd(a, b) {
      return b == 0 ? a : this.gcd(b, a % b)
    },
    aspectRation(width, height) {
      const r = this.gcd(width, height)
      return `${width / r}:${height / r}`
    },
    countdownText: function () {
      this.remainingCountText = this.maxCountText - this.adz.content.text.length
      this.hasErrorText = this.remainingCountText < 0
    },
    countdownUrl: function () {
      this.remainingCountUrl = this.maxCountUrl - this.adz.content.urlText.length
      this.hasErrorUrl = this.remainingCountUrl < 0
    },
    countdownTitre: function () {
      // console.log(this.maxCountTitre - this.adz.content.metaTitle.length)
      this.remainingCountTitre = this.maxCountTitre - this.adz.content.metaTitle.length
      this.hasErrorUrlText = this.remainingCountTitre < 0
    },
    showMoreOptions() {
      if (this.moreOptions) {
        this.moreOptions = false
      } else {
        this.moreOptions = true
      }
    },
    async getCtaOptions() {
      this.ctaLang = this.adz.ctaLang ? this.adz.ctaLang : 'en'
      this.adz.ctaLang = this.ctaLang

      const fbAdzCallToActions= new FbAdzCallToActionsDB()

      this.ctaOptions = await fbAdzCallToActions.readAll(null, false, 'label', 'asc')
    },
    setCtaLabel(e) {
      this.adz.content.ctaSelectedLabel = e.target.options[e.target.selectedIndex].textContent
    },
    setCtaLabelLang() {
      this.ctaLang = this.ctaLang === 'en' ? 'fr' : 'en'

      this.adz.ctaLang = this.ctaLang

      const saveVal = document.querySelector('[name="cta"]').value
      document.querySelector('[name="cta"]').value = ''
      setTimeout(() => {
        document.querySelector('[name="cta"]').value = saveVal

        this.adz.content.ctaSelectedLabel = document.querySelector('[name="cta"]').options[document.querySelector('[name="cta"]').selectedIndex].textContent
      }, 155)
    },
    validateUserInput() {
      if (this.adz.content.url) {
        const isUrlRegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
        if (!isUrlRegExp.test(this.adz.content.url)) {
          doAlert({
            title: 'Websitel URL must be a valid url with format: "https://.../"',
            type: 'error',
          })
          return false
        }
      }

      // if (this.adz.content.urlText) {
      //   const isUrlRegExp = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
      //   if(!isUrlRegExp.test(this.adz.content.urlText)) {
      //     doAlert({
      //       title: 'Display Link must be a valid url with format: "domain.ext"',
      //       type: 'error'
      //     })
      //     return false
      //   }
      // }

      return true
    },
    resetCrop(imgData) {
      if (imgData) {
        this.imgSrc = imgData
        this.shouldClosePopup = false
        // rebuild cropperjs with the updated source
        this.$refs.cropper.replace(imgData)
        this.isSaving = false
      }
    },
    ...mapActions('users', ['selectClient', 'selectCampaign', 'selectAdz']),
    ...mapActions('app', ['enterLiveEditMode']),
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';
@import '@/theme/typography.scss';
@import '@/theme/button.scss';
@import '@/theme/forms.scss';
@import '@/theme/page-title.scss';
@import '@/theme/crop-pop.scss';

.content-wrap {
  display: flex;
}

.left-wrap {
  width: 100%;
  margin-right: 20px;
}

.inner-page-header {
  justify-content: flex-start;
}

.create-fb-ads-form {
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  text-align: left;

  .link-p {
    color: $purple-blue;
  }

  input[type='text'] {
    width: 100%;
    display: inline-block;
  }

  .add-file {
    background-image: url(../../assets/img/add-file.svg);
    background-repeat: no-repeat;
    background-position: calc(100% - 20px) center;
    cursor: pointer;

    width: 100%;
    height: 60px;
    padding: 20px 15px;
    font-size: 14px;
    color: #637082;
    font-weight: 600;
    background-color: #fff;
    font-family: 'Open Sans', sans-serif !important;
    border-radius: 3px;
    margin-bottom: 5px;
    border: 1px solid #e0e2e6;
  }

  textarea {
    max-width: 660px;
  }

  select {
    padding-left: 20px;
  }

  .field-wrap {
    position: relative;
    .counter {
      position: absolute;
      right: 20px;
      bottom: 20px;
      margin-bottom: 0;
      font-size: 14px;
      color: #5cc;
      font-weight: 600;
      opacity: 1;

      &.text-danger {
        color: #f12f80;
      }
    }
  }

  .sub-label {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 30px;
    opacity: 0.7;

    a {
      color: #4628ff;
    }
  }

  .label {
    color: $dark-grey;
    font-size: 14px;
    font-weight: 600;
  }

  .multiple-action-button {
    .main-button {
      display: flex;
      justify-content: center;
    }

    .default-action {
      padding: 15px 25px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      min-width: 200px;
      cursor: pointer;

      font-family: 'Open Sans', sans-serif;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      text-align: left;

      transition: all 0.4s ease;

      background: linear-gradient(to right, #2e7df2, #8a23f1);
    }

    .more {
      display: flex;
      align-items: center;
      padding: 15px 25px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      cursor: pointer;
      background-color: #8a23f1;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    .options {
      width: 263px;
      margin: 0 auto;
      border-left: 1px solid #e0e2e6;
      border-right: 1px solid #e0e2e6;
      border-bottom: 1px solid #e0e2e6;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      display: none;
      z-index: -1;

      &.show {
        display: block;
        z-index: 1;
      }

      button {
        width: 100%;
        background-color: #fff;
        padding: 15px 25px;
        font-family: 'Open Sans', sans-serif;
        color: $dark-grey;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
        cursor: pointer;
        transition: all 0.4s ease;

        &:hover {
          background-color: #e0e2e6;
        }

        &:last-child {
          border-top: 1px solid #e0e2e6;
        }
      }
    }
  }

  .bt-wrap {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;

    &.is-loner {
      padding: 10px 0;

      .bt {
        margin: 0 auto;
      }
    }
  }

  .bt {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(1 / 2 * 100% - (1 - 1 / 2) * 20px);
    padding: 15px 20px 15px 20px;

    &.save {
      background: $dark-grey;
    }
    p {
      position: relative;
      left: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 600;
      color: #fff;
      transition: all 0.2s ease;
    }

    &:hover {
      p {
        left: -15px;
      }
    }
  }
}

// textarea {
//   width: 100%;
//   height: 100px;
// }
.preview-area {
  width: 307px;
}
.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}
.preview-area p:last-of-type {
  margin-top: 1rem;
}
.preview {
  display: none;
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
.crop-placeholder {
  width: 100%;
  height: 200px;
  background: #ccc;
}
.cropped-image img {
  max-width: 100%;
}
</style>
