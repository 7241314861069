export const translatedCta = (label, lang = 'fr') => {
  const translations = {
    'fr': {
      'LEARN_MORE': 'En savoir plus',
      'LISTEN_NOW': 'Écouter maintenant',
      'SHOP_NOW': 'Acheter',
      'SIGN_UP': `S'abonner`,
      'CONTACT_US': 'Nous joindre',
      'SUBSCRIBE': `S'inscrire`,
      'ORDER_NOW': 'Commander maintenant',
      'BOOK_TRAVEL': 'Réserver maintenant',
      'SEE_MENU': 'Afficher le menu',
      'REQUEST_TIME': `Demander l'heure`,
      'GET_SHOWTIMES': `Obtenir l'horaire`,
      'WATCH_MORE': 'Regarder plus',
      'INSTALL_MOBILE_APP': 'Installer',
      'USE_APP': `Utiliser l'application`,
      'PLAY_GAME': 'Jouer à un jeu',
      'DOWNLOAD': 'Télécharger',
      'GET_QUOTE': 'Obtenir un devis',
      'APPLY_NOW': 'Inscrivez-vous dès maintenant',
      'WHATSAPP_MESSAGE': 'Envoyer un message avec WhatsApp',
    },
    'en': {
      'LEARN_MORE': 'Learn more',
      'LISTEN_NOW': 'Listen now',
      'SHOP_NOW': 'Shop now',
      'SIGN_UP': `Sign up`,
      'CONTACT_US': 'Contact us',
      'SUBSCRIBE': `Subscribe`,
      'ORDER_NOW': 'Order Now',
      'BOOK_TRAVEL': 'Book travel',
      'SEE_MENU': 'See menu',
      'REQUEST_TIME': `Request time`,
      'GET_SHOWTIMES': `Get showtimes`,
      'WATCH_MORE': 'Watch more',
      'INSTALL_MOBILE_APP': 'Install Mobile App',
      'USE_APP': `Use App`,
      'PLAY_GAME': 'Play game',
      'DOWNLOAD': 'Download',
      'GET_QUOTE': 'Get a quote',
      'APPLY_NOW': 'Apply now',
      'WHATSAPP_MESSAGE': 'WhatsApp Message',
    }
  }
  return translations[lang][label]
}
